@import '../../../../styles/helpers';

.fullReview {
    position: relative;
  
    .reviewContent {
      display: none;
    }
  
    &.default-view {
      margin: auto;
      margin-top: 56px;
      width: calc(100% - 48px);
      aspect-ratio: unset;
      height: auto;
  
      @include media(portrait) {
        width: calc(100% - 128px);
      }
  
      @include media(landscape) {
        display: flex;
        width: calc(100% - 144px);
      }
  
      @include media(desktopAll) {
        display: flex;
        width: 1280px;
      }
  
      @include media(desktopSmall) {
        width: 1040px;
      } 
  
      @include media(desktop) {
        width: 1040px;
      }
  
      img {
        position: static !important;
      }
  
      .preview {
        aspect-ratio: 3 / 2;
      }
  
      .reviewContent {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        padding: 32px;
        @include setProperty(background-color, var(--secondary-800), var(--light-line-low-accent));
        overflow: hidden;
  
        @include media(landscape) {
          max-width: 60%;
        }
  
        @include media(desktopAll) {
          padding: 40px;
          justify-content: space-between;
          max-width: 50%;
        }
  
        .reviewText {
          font-size: 16px;
          line-height: 32px;
          font-weight: 300;
          letter-spacing: 0.183px;
          @include setProperty(color, var(--secondary-50), var(--secondary-500));
  
          span {
            opacity: 0.6;
          }
        }
  
        .reviewIcon {
          width: 40px;
          height: 40px;
        }
  
        .reviewFooter {
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-self: stretch;
    
          @include media(notMobile) {
            flex-direction: row;
            justify-content: space-between;
          }
        }
      }
  
      button {
        width: 100%;
  
        .author {
          display: none;
        }
      }
  
      .video {
        width: 100%;
        aspect-ratio: 3 / 2;
  
        @include media(landscape) {
          height: auto;
        }
  
        @include media(desktopAll) {
          height: auto;
        }
      }
  
      .author {
        position: relative;
        background: none;
        gap: 16px;
        padding: 0;
  
        &__avatar {
          width: 48px;
          height: 48px;
        }
  
        &__name {
          font-size: 16px;
          line-height: 16px;
          margin-bottom: 4px;
          letter-spacing: 0.183px;
        }
  
        &__position {
          font-size: 14px;
          line-height: 28px;
          font-weight: 300;
        }
  
        &__clutch {
          display: flex;
          align-items: center;
          align-self: flex-end;
          gap: 8px;
          border-radius: 200px;
          padding: 12px 16px;
          @include setProperty(background-color, var(--secondary-700), var(--secondary-50));
          width: fit-content;
          height: fit-content;
  
          .logo {
            height: 12px;
          }
  
          @include media(notMobile) {
            width: 194px;
          }
        }
      }
  
      .blur {
        width: 429px;
        height: 40px;
        position: absolute;
        right: -159px;
        bottom: 43px;
        border-radius: 200px;
        opacity: 0.4;
        background-color: var(--primary-400);
        filter: blur(85px);
  
        @include media(portrait) {
          right: 200px;
        }
  
        @include media(landscape) {
          right: 100px;
        }
  
        @include media(desktopAll) {
          bottom: -33px;
          right: 20%;
        }
       }
    }
  }
  
  .video {
    height: 100%;
  
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  
  .preview {
    cursor: pointer;
  }
  
  .author {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px 24px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    width: 100%;
    color: var(--secondary-50);
  
    @include media(desktopHuge) {
      gap: 36px;
      padding: 37px 55px;
  
      &__avatar {
        width: 110px;
        height: 110px;
      }
  
      &__name {
        font-size: 37px;
        line-height: 45px;
      }
  
      &__position {
        font-size: 27px;
        line-height: 33px;
      }
    }
  
    @include media(mobile) {
      gap: 5px;
    }
  }