@import "src/styles/helpers";

.author {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 24px;
  letter-spacing: 0.04em;
  color: var(--secondary-50);

  &__avatar {
    border-radius: 50%;
    overflow: hidden;
    @include imgCover;
    width: 40px;
    height: 40px;
    position: relative;
    flex-shrink: 0;

    img {
      object-fit: cover !important;
    }
  }

  &__name {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 8px;

    @include media(mobile) {
      font-size: 10px;
      line-height: 12px;
      margin-bottom: 5px;
    }
  }

  &__position {
    font-size: 12px;
    line-height: 15px;
    opacity: 0.6;

    @include media(mobile) {
      font-size: 7px;
      line-height: 10px;
    }
  }
}
