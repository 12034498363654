@import 'src/styles/helpers';

.reviews {
  width: 100vw;
  overflow: hidden;
  padding: 100px 0;

  @include media(portrait) {
    padding: 120px 0;
  }

  @include media(landscape) {
    padding: 144px 0;
  }

  @include media(desktopAll) {
    padding: 144px 0;
  }

  // &.default-view  {
  //   padding: 64px 0;
    
  //   @include media(landscape) {
  //     padding: 80px 0;
  //   }

  //   @include media(desktopAll) {
  //     padding: 120px 0;
  //   }

  //   header {
  //     display: flex;
  //     flex-direction: column-reverse;
  //     align-items: flex-start;
  //     gap: 32px;
  //     padding: 0;

  //     @include media(portrait) {
  //       gap: 48px;
  //     }
    
  //     @include media(landscape) {
  //       padding-left: 72px;
  //       flex-direction: row-reverse;
  //       gap: 48px;
  //     }
    
  //     @include media(desktopAll) {
  //       padding-left: 320px;
  //       flex-direction: row-reverse;
  //       place-content: flex-end;
  //       gap: 64px;
  //     }
    
  //     @include media(desktopSmall) {
  //       padding-left: 120px;
  //     }
    
  //     @include media(desktop) {
  //       padding-left: 200px;
  //     }
  //   }
  // }

  &__content {

    &.uiux-page-view {
      position: relative;
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 32px;
      padding: 0;

      @include media(portrait) {
        gap: 48px;
      }
    
      @include media(landscape) {
        padding-left: 72px;
        flex-direction: row-reverse;
        gap: 48px;
      }
    
      @include media(desktopAll) {
        padding-left: calc((100vw - 1280px) / 2);
        flex-direction: row-reverse;
        place-content: flex-end;
        gap: 64px;
      }
    
      @include media(desktopSmall) {
        padding-left: 120px;
      }
    
      @include media(desktop) {
        padding-left: 200px;
      }
    }
  }

  &.uiux-page-view {
    display: flex;
    flex-direction: column;
    gap: 64px;
    padding: 64px 0;

    @include media(notMobile) {
      padding: 120px 0;
    }

    @include media(portrait) {
      padding: 104px 0;
    }

    header {
      h2 {
        max-width: 480px;
      }

      @include media(desktop) {
        margin: 0 !important;
        margin-left: 200px !important;
      }

      @include media(desktopHuge) {
        margin: 0 !important;
        margin-left: 320px !important;
      }
    }

    @include media(notMobile) {
      gap: 92px;
      padding: 120px 0;
    }

    @include media(portrait) {
      padding: 104px 0;
    }

    @include media(desktopAll) {
      gap: 140px;
    }

    @include media(desktopSmall) {
      gap: 92px;
    }
  }
}

.title {
  font-size: 40px;
  line-height: 48px;

  @include media(tablet) {
    font-size: 28px;
    line-height: 36px;
  }

  @include media(mobile) {
    font-size: 28px;
    line-height: 36px;
  }
}

.text {
  margin-top: 12px;
  font-size: 14px;
  line-height: 2;
  @include setProperty(font-weight, 300, 500);
  max-width: 720px;

  &.uiux-page-view {
    display: none;
  }

  @include media(desktopHuge) {
    font-size: 20px;
  }

  @include media(desktopBig) {
    max-width: 720px;
  }

  @include media(portrait) {
    margin-top: 32px;
  }

  @include media(mobile) {
    margin-top: 32px;
  }
}